import Service from '@/_helpers/Service'

interface CreateAdvertisingCampaign {}
interface CreateAdvertisingCampaignProps {
    email_server_type: string
    email_account: number
    name: string
    sender?: string
    bloggers: string[]
    channels: string[]
    exclude_channels: string[]
    exclude_bloggers: string[]
    set_id: number
    message: string
    subject: string
}
const createAdvertisingCampaign = new Service<CreateAdvertisingCampaign>({
    url: '',
    method: 'POST',
})
export const createAdvertisingCampaignReq = (token: string, data: CreateAdvertisingCampaignProps) => {
    return createAdvertisingCampaign.call(
        {
            url: '/core/outreach/advertising_campaigns/',
            data,
        },
        token
    )
}

export type CampaignStatus = 'active' | 'draft' | 'archived' | 'paused'
export interface Campaign {
    id: string
    name: string
    stats: {
        chats_count: number
        answered_chats_count: number
        opened_rate_absolute: number
        opened_rate_relative: number
        bounce_rate_absolute: number
        bounce_rate_relative: number
        created_at: string
        sender: string
        status: CampaignStatus
    }
    email_account?: any
}
export interface AdvertisingCampaigns {
    count: number
    results: Campaign[]
    next: any
    previous: any
}
const getAdvertisingCampaigns = new Service<AdvertisingCampaigns>('')
export const getAdvertisingCampaignsReq = (token: string, status?: CampaignStatus) => {
    const params = new URLSearchParams({})
    if (status) {
        params.append('status', status)
    }

    return getAdvertisingCampaigns.call(
        {
            url: `/core/outreach/advertising_campaigns/?${params.toString()}`,
        },
        token
    )
}

export enum CampaignAction {
    Activate = 'activate',
    Archive = 'archive',
    Pause = 'pause',
}
export const campaignsActions = new Service('')
export const campaignsActionsReq = (token: string, action: CampaignAction, campaigns: string[]) => {
    return campaignsActions.call(
        {
            url: `/core/outreach/advertising_campaigns/${action}/`,
            method: 'POST',
            data: { ids: campaigns },
        },
        token
    )
}

export interface IGetUserMails {
    count: number
    results: IMails[]
    next: any
    previous: any
}
interface IMails {
    day_limit: number
    email: string
    email_server_type: 'gmail' | 'smtp/imap'
    id: number
    sender_name: string
    signature: string
    user: number
    total_emails: number
    email_credentials?: {
        smtp: MailServerData
        imap: MailServerData
    }
    sent_today: number
}

const GetUserMails = new Service<IGetUserMails>('')
export const GetUserMailsReq = (token: string) =>
    GetUserMails.call(
        {
            url: `/core/outreach/accounts/`,
        },
        token
    )

export interface MailServerData {
    host: string
    port: number
    username: string
    password: string
    enable_ssl: boolean
}

export interface SmtpData {
    email: string
    sender_name: string
    signature: string
    email_server_type: string
    day_limit: number
    daily_ramp_up: number
    email_credentials?: {
        smtp: MailServerData
        imap: MailServerData
    }
}

const createAccount = new Service<IMails>('')
export const createAccountReq = (token: string, data: SmtpData) =>
    createAccount.call(
        {
            url: `/core/outreach/accounts/`,
            method: 'POST',
            data,
        },
        token
    )

const editAccount = new Service<IMails>('')
export const editAccountReq = (token: string, data: SmtpData, id: number) =>
    editAccount.call(
        {
            url: `/core/outreach/accounts/${id}/`,
            method: 'PATCH',
            data,
        },
        token
    )

const checkImap = new Service('')
export const checkImapReq = (token: string, data: MailServerData) => {
    return checkImap.call(
        {
            url: `/core/outreach/accounts/check_imap/`,
            method: 'POST',
            data,
        },
        token
    )
}

const checkSmtp = new Service('')
export const checkSmtpReq = (token: string, data: MailServerData) => {
    return checkSmtp.call(
        {
            url: `/core/outreach/accounts/check_smtp/`,
            method: 'POST',
            data,
        },
        token
    )
}

const oauthGmail = new Service<any>('')
export const oauthGmailReq = (token: string) =>
    oauthGmail.call(
        {
            url: `/core/social-accounts/login/google-email?approval_prompt=force`,
        },
        token
    )

export const addBloggersToCampaign = new Service('')
export const addBloggersToCampaignReq = (
    token: string,
    advertising_campaign: string,
    bloggers: string[],
    channels: string[]
) => {
    return campaignsActions.call(
        {
            url: `/core/outreach/chats/`,
            method: 'POST',
            data: {
                advertising_campaign: advertising_campaign,
                bloggers: bloggers,
                channels: channels,
            },
        },
        token
    )
}
