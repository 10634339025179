import React, { FC, memo, useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import useLikes from '@/pages/Lists/ListOfBloggers/useLikes'
import { GetBloggersCommentsReq } from '@/pages/Lists/model/service'
import useToken from '@/_helpers/Hooks/useToken'
import { SetAllBloggers, SetDefault } from '@/components/MassAddToList/_model/actions'
import { Platform } from '@/pages/Reports/types'

import { useAdapter, useSearch } from '../../SearchAdapter'
import NoResults from '../SearchItems/NoResults/NoResults'

export interface IBloggerList {
    className?: string
}

interface IComment {
    external_id: string
    created_at: string
    id: number
    text: string
}

const BloggerList: FC<IBloggerList> = (props) => {
    const token = useToken()
    const Adapter = useAdapter()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!token) return
        GetBloggersCommentsReq(token)
    }, [token])
    let likedBloggers: any = []
    useLikes().forEach((item: any) => {
        likedBloggers[
            item.external_id || item.blogger_id || item.channel_id || item.twitch_channel_id || item.vk_account_id
        ] = item.id
    })

    const { restart, selected } = useSearch((state) => state)

    useEffect(() => {
        restart()
    }, [restart])

    //comment
    const [commentsOfBloggers, setCommentsOfBloggers] = useState<IComment[]>()

    useEffect(() => {
        if (!token) return
        GetBloggersCommentsReq(token).then((res) =>
            res.forEach((item: IComment) => {
                setCommentsOfBloggers(res)
            })
        )
    }, [token])
    const getCommentText = useCallback(
        (id: string) => {
            if (!commentsOfBloggers) return

            const temp = commentsOfBloggers.filter((comment: IComment) => comment.external_id === id)
            if (temp.length === 0) return
            return { comment: temp[0].text, comment_id: temp[0].id }
        },
        [commentsOfBloggers]
    )
    useEffect(() => {
        const url = window.location.pathname
        const pl = url.includes(Platform.YouTube)
            ? Platform.YouTube
            : url.includes(Platform.Twitch)
            ? Platform.Twitch
            : url.includes(Platform.Vk)
            ? Platform.Vk
            : url.includes(Platform.Tg)
            ? Platform.Tg
            : url.includes(Platform.TikTok)
            ? Platform.TikTok
            : Platform.Instagram

        const externalIDs = selected.items.map((item) => {
            return {
                id: item.id + '',
                externalId: item.id + '',
                name: item.fullName,
                avatar: item.avatar,
                username: item.username,
                hasEmail: item.has_email ?? item.hasEmail,
                platform: pl,
            }
        })
        dispatch(SetAllBloggers(externalIDs))
    }, [dispatch, selected.items])

    useEffect(() => {
        return () => {
            dispatch(SetDefault)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const searchResults = React.useMemo(() => {
        //to hide tiktok report that was not bought
        return selected.items.filter((item) => item.hiddenReportId === undefined && item.isHidden !== true)
    }, [selected.items])

    return (
        <div className={props.className}>
            {selected.items.length === 0 && !selected.loading ? (
                <NoResults />
            ) : (
                searchResults.map((item, i) => (
                    <Adapter.Item
                        {...item}
                        index={i + 1}
                        likedId={likedBloggers[item.id.toString()]}
                        key={item.id + getCommentText(item.id + '') + likedBloggers[item.id.toString()]}
                        margin={'0 0 1px'}
                        comment={getCommentText(item.id + '')}
                    />
                ))
            )}
        </div>
    )
}

export default memo(BloggerList)
