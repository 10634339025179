import React, { FC, ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Flex from '@/_yc/Flex'
import Button from '@/components/Button'
import Text from '@/components/Text'
import Icons from '@/components/Icons'
import cn from './CSVLoader.module.scss'

interface ICSVLoader {
    children: ReactNode
    changeBloggers: Function
    dropCSVfile: Function
    file: File | undefined
    setFile: Function
}
const CSVLoader: FC<ICSVLoader> = ({ children, changeBloggers, dropCSVfile, file, setFile }) => {
    const { t } = useTranslation()

    const fileReader = new FileReader()

    const handleOnChange = (e: any) => {
        const temp = e.target.files[0]
        setFile(temp)

        if (temp) {
            fileReader.onload = function (event) {
                const csvOutput = event?.target?.result
                if (typeof csvOutput === 'string') {
                    getBloggersFromCSV(csvOutput)
                }
            }

            fileReader.readAsText(temp)
        }
    }

    const getBloggersFromCSV = useCallback(
        (text: string) => {
            const bloggers = text.split(/\r?[\n,]/)
            changeBloggers(bloggers)
        },
        [changeBloggers]
    )

    return (
        <>
            <Flex column>
                {!file ? (
                    <Flex className={cn.root} align="center" content="center">
                        <Button htmlFor="csvFileInput" onClick={() => document.getElementById('csvFileInput')?.click()}>
                            {t('aud_insides.mass_parsing.choose_file')}
                        </Button>

                        <input
                            type={'file'}
                            id={'csvFileInput'}
                            accept={'.csv'}
                            onChange={handleOnChange}
                            style={{ display: 'none' }}
                        />
                    </Flex>
                ) : (
                    <>{children}</>
                )}

                {file && (
                    <Flex className={cn.name} content="space-between" align="center">
                        <Text>
                            {file?.name + ' '}
                            <span>({file?.size}K)</span>
                        </Text>
                        <Icons
                            name="close--small"
                            onClick={() => {
                                setFile(undefined)
                                dropCSVfile()
                            }}
                        />
                    </Flex>
                )}
            </Flex>
        </>
    )
}

export default CSVLoader
