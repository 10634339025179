import React from 'react'
import { BasicFilter } from '../BasicFilter'
import 'react-calendar/dist/Calendar.css'
import Calendar from 'react-calendar'
import './picker.scss'
import FilterBase from '../FilterBase'
import { useComputedStyle } from '@/_helpers/Hooks/useComputedStyle'
import Popup from '@/_yc/Popup'
import Text from '@/components/Text'
import useOpenClose from '@/_helpers/useOpenClose'
import { useTranslation } from 'react-i18next'
import Tag from '@/pages/SearchPage/Bloggers/SearchPanel/Chips/Tag'
import cn from './CalendarFilter.module.scss'
import i18next from 'i18next'
import Button from '@/components/Button'
import moment from 'moment'
import { Checkbox, Flex } from '@/_yc'
import { trackFilterChange } from '@/_helpers/amplitude/track'
require('moment/locale/ru')
require('moment/locale/pt')
export type IWithDate = {
    dates: Array<string>
    range: boolean
}
const zIndex = { zIndex: 5 }

export class CalendarFilter<ServerData> extends BasicFilter<ServerData, IWithDate> {
    getDefaultValue() {
        return {
            dates: [],
            range: false,
        }
    }

    MainView = (props: { filterName: string }) => {
        return <div>{props.filterName}</div>
    }

    View = () => {
        const [state, setState, clear] = this.useFilterState()
        const { ref } = useComputedStyle((style) => ({ width: style.width, marginTop: 7 }), [])
        const [isOpen, , close, toggle] = useOpenClose(false)
        const { t } = useTranslation()
        const [range, , , tog] = useOpenClose(true)
        moment.locale(i18next.language)

        return (
            <>
                <FilterBase onClick={toggle} ref={ref} withArrow isActive={isOpen}>
                    {!!state.dates[0] && !!state.dates[1] ? (
                        <Text color="gray-4">{`${moment(state.dates[0]).format('DD MMM YY')} - ${moment(
                            state.dates[1]
                        ).format('DD MMM YY')}`}</Text>
                    ) : !!state.dates[0] ? (
                        <Text color="gray-4">{`${moment(state.dates[0]).add(1, 'day').format('DD MMMM YY')}`}</Text>
                    ) : (
                        <Text color="gray-4">{t('filters.global.select_date')}</Text>
                    )}
                </FilterBase>
                <Popup isOpen={isOpen} style={zIndex} onClose={toggle} anchor={ref.current}>
                    <div className={cn['calendar--form']}>
                        <div className={cn.calendar}>
                            <Calendar
                                locale={
                                    i18next.language === 'ru' ? 'ru-RU' : i18next.language === 'en' ? 'en-GB' : 'pt-PO'
                                }
                                showFixedNumberOfWeeks
                                selectRange={range ? true : false}
                                formatMonth={(loc, date) => moment(date).format('MMM')}
                                onChange={(i: any) => {
                                    const val = range
                                        ? {
                                              dates: [
                                                  moment(i[0]).add(1, 'day').toISOString().slice(0, 10),
                                                  moment(i[1]).toISOString().slice(0, 10),
                                              ],
                                              range: true,
                                          }
                                        : { dates: [moment(i.toString()).toISOString().slice(0, 10)], range: false }

                                    trackFilterChange({
                                        t,
                                        title: this.options.title,
                                        value: val.dates.join(),
                                        platform: this.options.platform,
                                    })
                                    setState(val)
                                }}
                            />
                        </div>
                        <Flex content="space-between" align="center">
                            <Button
                                variant="transparent"
                                className={cn.button}
                                onClick={() => {
                                    clear()
                                    close()
                                }}
                            >
                                <Text color="gray-4" fSize={10}>
                                    {t('filters.global.clear')}
                                </Text>
                            </Button>
                            <Flex margin="8px 12px 8px">
                                <Checkbox margin="0 5px" value={range} onChange={tog}>
                                    Range
                                </Checkbox>
                                <Text color="gray-4" fSize={10}>
                                    {t('filters.global.time_frame')}
                                </Text>
                            </Flex>
                        </Flex>
                    </div>
                </Popup>
            </>
        )
    }
    Tags = () => {
        const { t } = useTranslation()
        const [{ dates, range }, , clear] = this.useFilterState()
        return (
            <Tag
                onDelete={clear}
                label={`${t(this.options.title)}: ${
                    range
                        ? `${moment(dates[0]).format('DD MMM YY')} - ${moment(dates[1]).format('DD MMM YY')}`
                        : moment(dates[0]).add(1, 'day').format('DD MMMM YY')
                }`}
            ></Tag>
        )
    }
}
