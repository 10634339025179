import React, { CSSProperties, FC, memo, useMemo } from 'react'

import cn from './Popup.module.scss'

import SearchItem from './SearchItem'
import Popper from '@/_yc/Popup'
import { AutocompleteResult } from '@/Reponses'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import ParseItem from './ParseItem'
import LoadCircle from '../LoadCircle/LoadCircle'
import { OpenReportPlace } from '@/_helpers/amplitude/types'

export interface IPopup {
    place: OpenReportPlace
    items: AutocompleteResult[]
    onChange: (external_id: string | number, username: string, fullName?: string) => void
    anchor: any
    isOpen: boolean
    style: CSSProperties
    onClose: VoidFunction
    width: string | number
    search: string
    onParsing: (username: string) => void
    error: boolean
    isLoading: boolean
    fullSearch?: string
}

const Popup: FC<IPopup> = ({ onChange, ...props }) => {
    const size = useWindowWidth('mobile')

    const showParser = useMemo(() => {
        return props.items.findIndex((item) => item.username === props.search) === -1
    }, [props.items, props.search])

    return (
        <Popper isOpen={props.isOpen} onClose={props.onClose} anchor={props.anchor} style={props.style}>
            <div
                onClick={props.onClose}
                className={cn.root}
                style={{
                    width: size === 'mobile' ? 'calc(100vw - 20px)' : props.width,
                }}
            >
                {showParser && (props.items.length > 0 || props.error) && !props.isLoading && (
                    <ParseItem search={props.search} onClick={props.onParsing} fullSearch={props.fullSearch} />
                )}

                {(props.items.length > 0 || props.error) &&
                    props.items.map((item) => <SearchItem key={item.external_id} place={props.place} {...item} />)}

                {props.isLoading && (
                    <div className={cn.loader}>
                        <LoadCircle />
                    </div>
                )}
            </div>
        </Popper>
    )
}

export default memo(Popup)
