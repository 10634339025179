import useToken from '@/_helpers/Hooks/useToken'
import { RootStore } from '@/_helpers/StoreType'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateAdvertisingCampaigns } from '../../Model/actions'
import { getAdvertisingCampaignsReq } from '../../Service/Campaigns'
import { Header } from './Header/Header'
import { Row } from './Row/Row'
import cn from './Table.module.scss'
import Flex from '@/_yc/Flex'
import Loader from '@/components/Loader'
import { EmptyState } from './EmptyState/EmptyState'
import { SetDefault } from '@/components/MassAddToList/_model/actions'
export const Table = () => {
    const token = useToken()
    const dispatch = useDispatch()
    const activeStatusFilter = useSelector((state: RootStore) => state.Outreach.campaignsFilter)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!token) return

        setIsLoading(true)
        if (activeStatusFilter === 'all') {
            getAdvertisingCampaignsReq(token).then((data) => {
                setIsLoading(false)

                dispatch(updateAdvertisingCampaigns(data))
            })
            return
        }

        getAdvertisingCampaignsReq(token, activeStatusFilter).then((data) => {
            setIsLoading(false)

            dispatch(updateAdvertisingCampaigns(data))
        })
    }, [dispatch, token, activeStatusFilter])

    const data = useSelector((state: RootStore) => state.Outreach.advertisingCampaigns)
    useEffect(() => {
        return () => {
            dispatch(SetDefault())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className={cn.table}>
            <Header />
            <div className={cn.rows}>
                {data.results.length === 0 && !isLoading && <EmptyState />}

                {data.results.map((item, i) => (
                    <Row key={item.id} data={item} index={i} />
                ))}

                {isLoading && (
                    <Flex content="center">
                        <Loader />
                    </Flex>
                )}
            </div>
        </div>
    )
}
