export const SEARCH = '/search'
export const DASHBOARD = '/dashboard'
export const LOGIN = '/login'
export const LISTS = '/audience'
export const REPORT = '/report'
export const SIGNUP = '/signup'
export const FORGOT = '/forgot'
export const VERIFY = '/verify-email'
export const RESET = '/reset-password'
export const SETTINGS = '/settings'
export const BILLING = '/billing'
export const TARIFFS = '/tariffs'
export const ABOUT = '/about'
export const OAUTH = '/oauth/'
export const BLOG = '/blog'
export const ADS_EXPLORER = '/ads'
export const CAMPAIGN_MANAGER = '/campaign-manager'
export const MEDIA_PLANNING = '/media-planning'
export const OUTREACH = '/outreach'
export const AFFILIATE = '/affiliate'
export const USER_DETAILS = '/details'
export const PAYMENT = '/payment'
export const RECENT_SEARCHES = '/recent'
export const FAVORITES = '/favorites'

export const INTERSECTIONS = '/intersections'
export const INTERSECTIONS_DEFAULT = '/intersections/all/'
export const INTERSECTIONS_CREATE = '/intersections/create/'
export const INTERSECTIONS_DEMO = '/intersections/demo/'

export const DEMO_REPORT = '/demo_report'

export const OPEN_REPORT = '/open'

export const REQUIRE_DEMO = '/require-demo'

export const FEEDBACK = '/feedback'

export const OAUTH_GMAIL_REDIRECT = '/oauth-gmail'
