import { combineReducers } from 'redux'

import { bloggers } from './users.reducer'
import { blogger, bloggerWords } from './blogger.reducer'
import * as Notification from '../../components/Notifier/model'
// import { audience_insides } from '../../pages/OLDAudienceInsides/Model/_reducers'
import { BloggerReport } from '../../pages/Reports/BloggerReport/Model/_reducers.js'
import authentication from '@/pages/Auth/LoginPage/Model/_reducers'
import ContextMenu_ from '../../_yc/ContextMenu/reducer.js'
import Commercial_ from '../../components/Commercial/Model/reducer.js'
import { reducer as HeaderMenu_ } from '../../components/HeaderMenu/Model/reducer'
import TariffPage_ from '@/pages/TariffPage/Model/reducers'
import search, { clickedFilter}from '@/pages/SearchPage/Model'
import report from "@/Layouts/ReportLayout/Model"
import AudienceInsights from '@/pages/Lists/model/reducers'
import MassAddToList from '@/components/MassAddToList/_model/reducers'
import { Outreach } from '@/pages/Outreach/Model/reducers'
import { Settings } from '@/pages/SettingsPage/Model/reducers'

const rootReducer = combineReducers({
    bloggers,
    blogger,
    bloggerWords,
    ...Notification,
    // audience_insides,
    BloggerReport,
    authentication,
    ContextMenu_,
    HeaderMenu_,
    TariffPage_,
    Commercial_,
    search,
    clickedFilter,
    report,
    AudienceInsights,
    MassAddToList,
    Outreach,
    Settings,
})

export default rootReducer