import React, { FC } from 'react'

import Flex from '@/_yc/Flex'
import Instagram from '@/_yc/Icons/Tariffs/Instagram'
import Youtube from '@/_yc/Icons/Tariffs/Youtube'
import { tariff } from '../../Model/types'

interface IIcons {
    tariffs: Array<tariff>
    option: string
}
const style = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',

    color: '#7685FF',
    alignSelf: 'center',
}

const TariffIcons: FC<IIcons> = ({ tariffs, option }) => {
    const getTariffType = () => {
        const platformsList = tariffs.map((t) => t.platforms)

        let isYoutube = false
        let isInstagram = false
        let isBoth = false

        for (let platforms of platformsList) {
            const includesInst = platforms.includes('instagram')
            const includesYoutube = platforms.includes('youtube')

            includesInst && (isInstagram = true)
            includesYoutube && (isYoutube = true)
            includesYoutube && includesInst && (isBoth = true)
        }

        if (isBoth) {
            return 'both'
        } else if (isYoutube && isInstagram) {
            return 'either'
        } else if (isInstagram) {
            return 'inst'
        } else {
            return 'yt'
        }
    }

    const type = getTariffType()

    return (
        <>
            <Flex const="space-between" align="center">
                {type !== 'yt' ? (
                    <Instagram
                        color={type === 'either' && option !== 'instagram' ? '#CCCCCC' : ''}
                        style={{ marginRight: '4px' }}
                    />
                ) : (
                    <Instagram color="#CCCCCC" style={{ marginRight: '4px' }} />
                )}
                {type === 'both' && <span style={style}>+</span>}
                {type === 'either' && <span style={style}>/</span>}
                {type === 'inst' ? (
                    <Youtube color="#CCCCCC" style={{ marginLeft: '4px' }} />
                ) : (
                    <Youtube
                        color={type === 'either' && option === 'instagram' ? '#CCCCCC' : ''}
                        style={{ marginLeft: '4px' }}
                    />
                )}
            </Flex>
        </>
    )
}

export default TariffIcons
