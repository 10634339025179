import React, { FC, useEffect } from 'react'

import cn from './PageLayout.module.scss'

import Header from '@/Layouts/PageLayout/header'

import Title from '@/_helpers/TitleControl'
import useClass from '@/_helpers/Hooks/useClass'
import Sidebar from './sidebar/Sidebar'

import {
    INTERSECTIONS_DEFAULT,
    LISTS,
    SEARCH,
    REPORT,
    DEMO_REPORT,
    INTERSECTIONS,
    ADS_EXPLORER,
    CAMPAIGN_MANAGER,
    OUTREACH,
    MEDIA_PLANNING,
} from '@/routs'

export interface IPageLayout {
    title: string
    className?: string
    color: string
    noPadding?: boolean
}

const links = [
    { title: 'top_menu.p1', to: SEARCH, icon: 'search', active: [SEARCH] },
    {
        title: 'top_menu.p2',
        to: LISTS,
        icon: 'bars',
        active: [LISTS, REPORT, DEMO_REPORT],
    },
    {
        title: 'top_menu.p6',
        to: `${CAMPAIGN_MANAGER}`,
        icon: 'campaign-manager',
        active: [CAMPAIGN_MANAGER],
        isNew: true,
    },
    {
        title: 'top_menu.p3',
        to: INTERSECTIONS_DEFAULT,
        icon: 'copy',
        active: [INTERSECTIONS],
    },
    {
        title: 'top_menu.p4',
        to: `${ADS_EXPLORER}/instagram`,
        icon: 'ads-explorer',
        active: [ADS_EXPLORER],
    },
    {
        title: 'top_menu.p7',
        to: `${OUTREACH}`,
        icon: 'outreach',
        active: [`${OUTREACH}`],
    },
    {
        title: 'top_menu.p8',
        to: `${MEDIA_PLANNING}`,
        icon: 'media-planner',
        active: [MEDIA_PLANNING],
    },
]

const PageLayout: FC<IPageLayout> = (props) => {
    useEffect(() => {
        new Title().label = props.title
    }, [props.title])

    const root = useClass(cn.root, props.className)

    const rootWithoutPadding = useClass(cn['root-no-padding'], props.className)

    return (
        <div className={props.noPadding ? rootWithoutPadding : root} id="page-layout-yolo">
            <Header color={props.color} links={links} />
            <div style={{ backgroundColor: '#F1F3F8' }}>
                <Sidebar links={links} />
                <div className={cn.children}>{props.children}</div>
            </div>
        </div>
    )
}

export default PageLayout
