import { NativeNotificationModel } from '@/Reponses'
import { Notification } from './Types'
import { ReadyType } from './MessageTypes'
// import OnParsing from './MessageTypes/OnParsing'
import BloggerReady from './MessageTypes/BloggerReady'
import ParsingError, { ParsingErrorType } from './MessageTypes/ParsingError'

const errors: ParsingErrorType[] = ['DOESNT_EXIST', 'PRIVATE', 'TOO_LARGE', 'TOO_SMALL', 'SERVICE_ERROR']

export default (native: NativeNotificationModel<any>[]): Notification[] => {
    const result: Notification[] = []

    native.forEach((item) => {
        const notif = parseNativeNotification(item)
        if (notif) result.push(notif)
    })

    return result
}

export const parseNativeNotification = (native: NativeNotificationModel<any>): Notification | null => {
    const Message = mapTypeToMessage(native.type, native.info, native.is_read)
    if (!Message) return null
    return {
        id: native.id,
        postDate: +new Date(native.created_date),
        Message,
        type: native.type,
    }
}

const mapTypeToMessage = (type: string, info: any, is_read: boolean): ReadyType | null => {
    if (is_read) return null
    switch (type) {
        case 'BLOGGER_ON_PARSING': {
            // if (!info.username || typeof info.username !== 'string') return null
            // return new OnParsing(info.username)
            return null
        }
        case 'BLOGGER_READY': {
            if (
                typeof info.username !== 'string' ||
                (typeof info.external_id !== 'number' && typeof info.external_id !== 'string')
            )
                return null
            return new BloggerReady(info.username, info.external_id)
        }
        case 'BLOGGER_ERROR': {
            if (!info.username || typeof info.username !== 'string' || !info.status || !errors.includes(info.status))
                return null
            return new ParsingError(info.username, info.status)
        }
        default:
            return null
    }
}
