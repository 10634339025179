import React, { FC, memo, useCallback } from 'react'
import cn from './Popup.module.scss'

import { Flex } from '@/_yc'
import Avatar from '@/components/Avatar'
import UI from '../UserIcon.svg'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import Arrow from './Arrow'

export interface IParseItem {
    search: string
    onClick: (search: string) => void
    fullSearch?: string
}

const ParseItem: FC<IParseItem> = ({ onClick, ...props }) => {
    const { t } = useTranslation()

    const click = useCallback(() => {
        if (props.fullSearch && props.fullSearch.includes('youtube.com')) {
            return onClick(props.fullSearch)
        }
        onClick(props.search)
    }, [props.search, props.fullSearch, onClick])

    return (
        <Flex onClick={click} content="space-between" align="center" className={cn.item}>
            <Flex align="center">
                <Avatar src={UI} className={cn.avatar} margin="0 12px 0 0" />
                <div>
                    <Text semibold color="violet-1">
                        {props.search}
                    </Text>
                    <Text color="gray-3" semibold fSize={12}>
                        {t('aud_insides.search_input.searchSub')}
                    </Text>
                </div>
            </Flex>
            <Text fSize={12} semibold color="gray-3">
                {t('aud_insides.search_input.button.analyze')} <Arrow className={cn.icon} />
            </Text>
        </Flex>
    )
}

export default memo(ParseItem)
